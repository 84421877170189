import React from "react"
import PropTypes from "prop-types"

import { COLORS } from "../styles/constants"

const Footer = ({ siteTitle, contact }) => (
  <footer
    style={{
      padding: "1rem",
      backgroundColor: COLORS.lightGray,
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 240px))",
        padding: "1rem 2rem",
        fontSize: ".85rem",
      }}
    >
      <div>
        <a
          style={{ textDecoration: "none", color: COLORS.gray }}
          href="/terms"
        >
          Terms &#38; Privacy
        </a>
      </div>
      <div style={{ color: COLORS.gray }}>
        © {new Date().getFullYear()}
        {` `}
        <a
          style={{ textDecoration: "none", color: COLORS.gray }}
          href="/"
        >
          {siteTitle}
        </a>
        {` | `}
        <a
          style={{ textDecoration: "none", color: COLORS.gray }}
          href={`mailto:${contact}`}
        >
          {`Contact`}
        </a>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
